.Button {
    font-size: 20px;
    position: relative;
    font-family: 'Circular Std', sans-serif;
    cursor: pointer;
    height: 74px;
}

.Button__inner1 {
    border: 0.75px solid;
    height: 64px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button__inner2 {
    border: 0.75px solid;
    height: 64px;
    width: 100%;
    position: absolute;
    top: 5px;
    left: 5px;
}

@media screen and (max-width: 1300px) {
    .Button {
        font-size: 16px;
        height: 50px;
    }
    
    .Button__inner1 {
        height: 40px;
    }
    
    .Button__inner2 {
        height: 40px;
    }
}