.Customization {
    height: 1042px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Customization__imageContainer {
    display: flex;
    justify-content: center;
    padding-right: 64px;
    margin-top: 64px;
}

.Customization__inner {
    height: 964px;
    width: 90%;
    background-color: #C19C7F;
    padding-left: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Customization__heading {
    font-weight: 500;
    font-size: 108px;
    font-family: 'Crimson Pro', serif;
    color: #3F3F3F;
}

.Customization__desc {
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0.025em;
    line-height: 110%;
    color: #FFFFFF;
    font-family: 'Sen', sans-serif;
    padding-right: 64px;
    margin-top: 64px;
}
.Customization__img {
    filter: drop-shadow(0px 2px 16px #C58452);
    margin: 0 22px;
    height: 360px;
    width: 360px;
}

.Customization__btnCon {
    display: flex;
    justify-content: center;
    margin-top: 64px;
    padding-right: 64px;
}

@media screen and (max-width: 1300px) {
    .Customization {
        height: auto;
    }

    .Customization__inner {
        height: auto;
        padding-left: 0px;
    }

    .Customization__img {
        margin: 22px 0;
        height: auto;
        width: 90%;
    }

    .Customization__imageContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
        margin-top: 32px;
    }
    
    .Customization__btnCon {
        margin-top: 20px;
        margin-bottom: 32px;
        padding-right: 0px;
    }

    .Customization__heading {
        padding-left: 20px;
    }

    .Customization__desc {
        padding: 0 20px;
    }
}

@media screen and (max-width: 1000px) {
    .Customization__heading {
        font-size: 32px;
        margin-top: 32px;
    }

    .Customization__desc {
        font-size: 16px;
        margin-top: 20px;
    }
}