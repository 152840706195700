@import url('http://fonts.cdnfonts.com/css/sen');

.Landingpage {
    height: 758px;
    background-image: url('../../assets/landing/landing.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5%;
    align-items: flex-end;
}

.LandingPage__heading {
    font-size: 108px;
    font-family: 'Crimson Pro', serif;
    color: #3F3F3F;
}

.LandingPage__desc {
    font-family: 'Sen', sans-serif;
    margin-top: 40px;
    font-size: 28px;
    width: 50%;
    text-align: end;
    margin-bottom: 1em;
    color: rgba(63, 63, 63, 0.8);
    letter-spacing: 0.025em;
    line-height: 110%;
}

@media screen and (max-width: 1300px) {
    .Landingpage {
        height: 600px;
        background-size: auto 100%;
    }

    .LandingPage__heading {
        font-size: 32px;
    }

    .LandingPage__desc {
        font-size: 14px;
        margin-top: 15px;
    }
}