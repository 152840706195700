/* .WallpaperDisplay {
} */

.WallpaperDisplay {
    height: 940px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-size: cover;
}

.WallpaperDisplay__drawer {
    height: 987px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WallpaperDisplay__innerdrawer {
    width: 90%;
    background-color: #C19C7F;
    padding-left: 65px;
    height: 825px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.WallpaperDisplay__heading {
    font-size: 108px;
    font-family: 'Crimson Pro', serif;
    color: #FFFFFF;
    line-height: 95%;
    margin-bottom: 24px;
}

.WallpaperDisplay__bigImageContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.WallpaperDisplay__text {
    width: 40%;
    margin: 0 32px;
    font-weight: 400;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: 0.025em;
    color: #E5E4E3;
    font-family: 'Sen', sans-serif;
}

.WallpaperDisplay__slider {
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: grab;
    position: relative;
}

.WallpaperDisplay__slider::-webkit-scrollbar {
    display: none;
}
.WallpaperDisplay__div {
    background: rgba(71,71,71,0.5);
    width: 45%;
    padding: 5%;
}

.WallpaperDisplay__leftBtn {
    position: absolute;
    height: 100%;
    width: 4%;
    z-index: 1000;
    cursor: pointer;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: black;
    font-family: 'Circular Std', sans-serif;
    font-weight: 900;
}

.WallpaperDisplay__rightBtn {
    position: absolute;
    height: 100%;
    width: 4%;
    z-index: 1000;
    right: 0;
    cursor: pointer;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: black;
    font-family: 'Circular Std', sans-serif;
    font-weight: 900;
}

.WallpaperDisplay__leftBtn:hover {
    background-color: rgba(255,255,255,0.3);
}

.WallpaperDisplay__rightBtn:hover {
    background-color: rgba(255,255,255,0.3);
}

.WallpaperDisplay__imgCon {
    overflow: hidden;
    height: 174px;
    width: 234px;
    flex: 1 0 auto;
    margin-right: 32px;
    filter: drop-shadow(0px 2px 16px #C58452);
}

.WallpaperDisplay__imgCon:last-child {
    margin-right: 0px;
}

.WallpaperDisplay__bigImage {
    height: 491px;
    width: 767px;
    filter: drop-shadow(0px 2px 16px #C58452);
}

@media screen and (max-width: 1300px) {
    .WallpaperDisplay {
        height: 550px;
    }

    .WallpaperDisplay__div {
        width: 80%;
    }

    .WallpaperDisplay__heading {
        font-size: 50px;
        margin-bottom: 24px;
    }

    .WallpaperDisplay__bigImageContainer {
        flex-direction: column;
        padding-top: 32px;
    }

    .WallpaperDisplay__text {
        width: 100%;
        margin: 32px;
        font-size: 18px;
    }

    .WallpaperDisplay__innerdrawer {
        height: auto;
        padding: 0 20px;
        margin-top: 32px;
    }

    .WallpaperDisplay__bigImage {
        width: 98%;
        height: auto;
    }

    .WallpaperDisplay__sliderContainer {
        margin-bottom: 32px;
        height: 14vw;
    }

    .WallpaperDisplay__imgCon {
        height: 14vw;
        width: 17vw;
        margin-right: 14px;
    }

    .WallpaperDisplay__drawer {
        height: auto;
    }
}
