.Collections {
    height: 670px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 7vw;
}

.Collections__heading {
    font-size: 48px;
    font-weight: 700;
    font-family: 'Circular Std', sans-serif;
}

.Collections__innerContainer {
    display: flex;
    justify-content: space-between;
}

.Collections__wallpaperDiv {
    height: 360px;
    width: 360px;
    background-color: black;
    margin-top: 52px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: drop-shadow(0px 2px 16px #707070);
}

.Collections__img {
    position: absolute;
    transition: all 0.5s;
    filter: drop-shadow(0px 2px 16px #707070);
}

.Collections__wallpaperTitle {
    z-index: 5;
    font-family: "Satisfy";
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    color: #FFFFFF;
    text-align: center;
    line-height: 95%;
}

@media screen and (max-width: 1000px) {
    .Collections {
        height: auto;
    }

    .Collections__heading {
        font-size: 32px;
        margin-top: 5vh;
    }

    .Collections__innerContainer {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5vh;
    }

}