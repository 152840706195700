.App {
  /* background-image: url('./assets/background.svg'); */
  /* background-size: cover; */
  /* background-repeat: repeat-y; */
  /* background-attachment: fixed; */
  height: 100vh;
  overflow-y: auto;
}

.scrollTopBtn {
  position: absolute;
  bottom: 2vh;
  right: 2vw;
  border-radius: 50%;
  border: none;
  background-color: #005C48;
  color: white;
  font-size: 28px;
  height: 60px;
  width: 60px;
  padding: 10px;
  display: none;
  cursor: pointer;
  z-index: 2000;
}

@media screen and (max-width: 1300px) {
  .scrollTopBtn {
    display: none !important;
  }
}