@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@500&display=swap');

.AboutUs {
    height: 1620px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutUs__inner {
    height: 1384px;
    width: 90%;
    background-color: #C19C7F;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AboutUs__heading {
    font-weight: 500;
    line-height: 94%;
    width: 950px;
    font-size: 108px;
    color: #FFFFFF;
    font-family: 'Crimson Pro', serif;
    margin-bottom: 0.5em;
}

.AboutUs__slider {
    display: flex;
}

.AboutUs__wallpaper {
    margin: 0 10px;
    height: 400px;
    width: 200px;
    filter: drop-shadow(0px 2px 16px #C58452);
}

.AboutUs__desc {
    word-wrap: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    font-family: 'Sen', sans-serif;
    width: 868px;
    margin-top: 96px;
    color: #FFFFFF;
}

@media screen and (max-width: 1300px) {
    .AboutUs__heading {
        font-weight: 500;
        font-size: 40px;
        width: 90%;
        margin-bottom: 1em;
    }

    .AboutUs__desc {
        font-size: 20px;
        width: 90%;
        margin-top: 96px;
    }
}