.Footer {
    height: 500px;
}

.Footer__UpperFooter {
    height: 404px;
    background: #C19C7F;
    display: flex;
    padding-top: 64px;
}

.Footer__LowerFooter {
    height: 96px;
    background: #545C52;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 450;
    font-size: 24px;
    line-height: 100%;
    color: #E5E4E3;
    font-family: 'Circular Std', sans-serif;
    text-align: center;
}

.Footer__InnerFooter {
    width: 332px;
    margin-left: 3%;
}

.Footer__heading {
    font-weight: 800;
    font-size: 48px;
    line-height: 37px;
    color: #25283D;
    margin-bottom: 45px;
}

.Footer__desc {
    font-family: 'Sen', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    color: #E5E4E3;
    margin-bottom: 16px;
}

.Footer__subheading {
    color: #FFFFFF;
    font-family: 'Crimson Pro', serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 111.5%;
    margin-bottom: 83px;
}

.Footer__Link {
    font-weight: 450;
    font-size: 24px;
    line-height: 100%;
    color: #E5E4E3;
    font-family: 'Circular Std', sans-serif;
    cursor: pointer;
    margin-bottom: 10px;
}

.Footer__map {
    height: 80%;
    width: 30%;
    margin-left: 3%;
}

.Footer__QL {
    width: 15%;
}

.ContactUs__contactDetailsDiv {
    display: flex;
    margin-left: -5%;
}

@media screen and (max-width: 1300px) {
    .Footer {
        height: auto;
    }
    
    .Footer__UpperFooter {
        height: auto;
        flex-direction: column;
        padding: 0 32px;
    }
    
    .Footer__InnerFooter {
        width: auto;
        margin-left: 0;
    }
    
    .Footer__QL {
        width: auto;
    }

    .Footer__heading {
        margin: 32px 0;
        max-width: 332px;
    }
    
    .Footer__desc {
        font-size: 20px;
        margin-bottom: 10px;
    }
    
    .Footer__subheading {
        margin-top: 32px;
        margin-bottom: 20px;
    }
    
    .Footer__Link {
        font-size: 20px;
    }

    .Footer__map {
        height: 400px;
        width: 100%;
        margin-left: 0;
        margin: 32px 0;
    }

    .ContactUs__contactDetailsDiv {
        margin-left: 0%;
    }

    .Footer__LowerFooter {
        height: auto;
        padding: 32px;
        text-align: center;
    }
}