.Stores {
    height: 268px;
    width: 100%;
    background-image: url('../../assets/stores.svg');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Stores__container {
    color: #E5E4E3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-align: center;
}

.Stores__icon {
    width: 42px;
    height: 42px;
    margin-bottom: 8px;
}

.Stores__name {
    font-weight: 500;
    font-size: 24px;
    font-family: 'Crimson Pro', serif;
    letter-spacing: 0.13em;
}

.Stores__counter {
    font-weight: 300;
    font-size: 64px;
    font-family: 'Crimson Pro', serif;
    line-height: 100%;
}

@media screen and (max-width: 1000px) {
    .Stores {
        height: auto;
        flex-direction: column;
    }

    .Stores__container {
        margin: 1em 0;
        width: 100%;
    }
}