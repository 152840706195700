@import url('http://fonts.cdnfonts.com/css/circular-std');

.Navbar {
    min-height: 100px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    z-index: 1050;
    font-family: 'Circular Std', sans-serif;
}

.Navbar__contact {
    font-weight: 450;
    font-size: 20px;
    line-height: 77.5%;
    margin-left: 72px;
    width: 30vw;
    color: #8B191C !important;
}

.Navbar__menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Navbar__menu-item {
    font-weight: 450;
    font-size: 20px;
    cursor: pointer;
}

.Navbar__menu-button {
    width: 168px;
    height: 64px;
    background-color: #8B191C;
    border: none;
    color: white;
    font-weight: 450;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1300px) {
    .Navbar__menu-item {
        font-weight: 400;
        font-size: 14px;
    }

    .Navbar__menu-button {
        width: 120px;
        height: 50px;
        font-weight: 400;
        font-size: 14px;
    }
}

@media screen and (max-width: 1000px) {
    .Navbar{
        width: 100% !important;
    }

    .Navbar__menu {
        align-items: flex-start;
        justify-content: center;
    }

    .Navbar__menu-button {
        height: 35px;
    }

    .Navbar__menu-item {
        height: 50px;
    }
    
    .Navbar__contact {
        width: 125px;
        margin-left: 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 98%;
    }
}