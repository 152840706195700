.displayImages {
    height: 1042px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayImages__inner {
    height: 825px;
    width: 90%;
    display: flex;
    background: #C19C7F;
    align-items: center;
    justify-content: center;
}

.displayImages__inner1 {
    display: flex;
    flex-direction: column;
}

.displayImages__innerTwin {
    display: flex;
    width: 690.3px;
    justify-content: space-between;
    margin-top: 24px;
}

.displayImages__inner2 {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.displayImages__button {
    height: 103.5px;
    margin-top: 24px;
    cursor: pointer;
    width: 338.4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 450;
    font-size: 32px;
    line-height: 40px;
    font-family: 'Circular Std', sans-serif;  
    background-color: white;
    box-shadow: 0px 2px 16px rgba(197, 132, 82, 0.25);
}

.displayAllImages {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayAllImages__slider::-webkit-scrollbar {
    display: none;
}

.displayAllImages__slider {
    width: 90%;
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: grab;
}

.displayAllImages__image {
    height: 174px;
    width: 234px;
    margin-right: 10px;
}