.Testimonials {
    height: 987px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Testimonials__inner {
    width: 90%;
    background-color: #C19C7F;
    padding-left: 20px;
    height: 825px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.Testimonial__quote {
    width: 75px;
    height: 75px;
    background: #A66C4B;
    position: absolute;
    top: -40px;
    left: 10px;
    border-radius: 50%;
    border: none;
    font-size: 96px;
    font-weight: 500;
    font-family: 'Crimson Pro', serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 77.5%;
}

.Testimonials__quote {
    width: 200px;
    height: 200px;
    background: #A66C4B;
    position: absolute;
    top: -80px;
    left: 30px;
    border-radius: 50%;
    border: none;
    font-size: 200px;
    font-weight: 500;
    font-family: 'Crimson Pro', serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 77.5%;
}

.Testimonials__textContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    margin-left: 64px;
}

.Testimonials__heading {
    font-weight: 700;
    font-size: 48px;
    color: white;
    font-family: 'Circular Std', sans-serif;
}

.Testimonials__desc {
    color: #E5E4E3;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    margin-top: 25px;
    margin-right: 32px;
}

.Testimonial__slider {
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
}

.Testimonial__slider::-webkit-scrollbar {
    display: none;
}

.Testimonial {
    background-color: white;
    height: 476px;
    min-width: 320px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Testimonial__text {
    height: 240px;
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #545C52;
    box-shadow: 0px 2px 15px #C58452;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 111.5%;
    letter-spacing: 0.055em;
    color: white;
    font-family: 'Circular Std', sans-serif;
}

.Testimonial__leftBtn {
    position: absolute;
    height: 100%;
    width: 4%;
    z-index: 1000;
    cursor: pointer;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: black;
    font-family: 'Circular Std', sans-serif;
    font-weight: 900;
    opacity: 0;
}

.Testimonial__rightBtn {
    position: absolute;
    height: 100%;
    width: 4%;
    z-index: 1000;
    right: 0;
    cursor: pointer;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: black;
    font-family: 'Circular Std', sans-serif;
    font-weight: 900;
    opacity: 0;
}

.Testimonial__leftBtn:hover {
    opacity: 1;
    background-color: rgba(255,255,255,0.3);
}

.Testimonial__rightBtn:hover {
    opacity: 1;
    background-color: rgba(255,255,255,0.3);
}

.Testimonial__sliderDiv {
    position: relative;
    width: 70%;
    height: 476px;
}


@media screen and (max-width: 1300px) {
    .Testimonials__inner {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    .Testimonials__heading {
        margin-top: 64px;
    }

    .Testimonials__textContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        margin-left: 0;
    }

    .Testimonial__sliderDiv {
        width: 95%;
        margin: 32px 0;
    }

    .Testimonials__quote {
        width: 12vw;
        height: 12vw;
        top: -5vw;
        left: 3vw;
        font-size: 15vw;
    }
}