.Contactus {
    height: 825px;
    background: #C19C7F;
    display: flex;
    flex-direction: column;
    padding: 0 194px;
    justify-content: center;
}

.Contactus__heading {
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    font-family: 'Circular Std', sans-serif;
    margin-bottom: 20px;
}

.Contactus__namePhone {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-family: 'Sen', sans-serif;
}

.Contactus__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 40px;
    gap: 8.09px;
    font-size: 28px;
    width: 48%;
    background: #E5E4E3;
    border: none;
    border-bottom: 0.404514px solid #FFFFFF;
    box-shadow: 0px 4px 16px #C58452;
}

.Contactus__phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 40px;
    gap: 8.09px;
    font-size: 28px;
    width: 48%;
    background: #E5E4E3;
    border: none;
    border-bottom: 0.404514px solid #FFFFFF;
    box-shadow: 0px 4px 16px #C58452;
}

.Contactus__email {
    display: flex;
    font-family: 'Sen', sans-serif;
    flex-direction: row;
    align-items: center;
    padding: 12px 40px;
    gap: 8.09px;
    font-size: 28px;
    background: #E5E4E3;
    border: none;
    border-bottom: 0.404514px solid #FFFFFF;
    box-shadow: 0px 4px 16px #C58452;
    margin-bottom: 20px;
}

.Contactus__message {
    display: flex;
    font-family: 'Sen', sans-serif;
    flex-direction: row;
    align-items: center;
    padding: 12px 40px;
    gap: 8.09px;
    font-size: 28px;
    background: #E5E4E3;
    border: none;
    border-bottom: 0.404514px solid #FFFFFF;
    box-shadow: 0px 4px 16px #C58452;
    margin-bottom: 20px;
}

@media screen and (max-width: 1300px) {
    .Contactus {
        height: auto;
        padding: 48px 10%;
    }
    
    .Contactus__heading {
        margin-bottom: 0px;
    }
    
    .Contactus__namePhone {
        flex-direction: column;
    }
    
    .Contactus__name {
        margin-top: 20px;
        width: 100%;
    }
    
    .Contactus__phone {
        width: 100%;
        margin-top: 20px;
    }
}