.Uppernav {
    height: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Uppernav__title {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 37px;
    color: #25283D;
    text-align: end;
    width: 269px;
}